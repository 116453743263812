import { useFrame, useThree } from "@react-three/fiber";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useEffect, useRef, useState } from "react";
import Sizes from "../../Utils/Sizes";
import { Edges, MeshDiscardMaterial, Svg, Text, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import WindowHandler from "../../Utils/WindowHandler";
import { LineMaterial } from 'three/addons/lines/LineMaterial.js';
import { Wireframe } from 'three/addons/lines/Wireframe.js';
import { WireframeGeometry2 } from 'three/addons/lines/WireframeGeometry2.js';

gsap.registerPlugin(
	ScrollTrigger,
);

export default function ContactButton() {
    
    const [pageReady, setPageReady] = useState(false)
    const [buttonVisible, setButtonVisible] = useState(false)
    const button = useRef()
    const buttonGroup = useRef()
    const buttonContainer = useRef()
    const { invalidate } = useThree()
    const { width, height } = Sizes()
    const isTouch = WindowHandler().isTouch

    const [multiplierHeight, setMultiplierHeight] = useState(.00065)

    const { nodes } = useGLTF('./models/3d_button.glb')

    useEffect(() => {
        if (!pageReady) return
        if (!document.querySelector('.bloque__hablamos_cont_btn')) return

        const duration = 1
        let diamondScrollTl = gsap.timeline({defaults: {duration: duration, ease: 'linear'}});
        diamondScrollTl.to(buttonContainer.current.position, {y: height * multiplierHeight}, 0);
        diamondScrollTl.fromTo(buttonGroup.current.rotation, {
            x: Math.PI * -1,
            y: Math.PI * .1,
            z: Math.PI * -.05,
        }, {
            x: Math.PI * 1,
            y: Math.PI * -.1,
            z: Math.PI * .05,
        }, 0);

        ScrollTrigger.create({
            trigger: '.bloque__hablamos_cont_btn',
            animation: diamondScrollTl,
            start: '0% 100%',
            end: '100% 0%',
            scrub: true,
        });

        ScrollTrigger.create({
            trigger: '.bloque__hablamos_cont_btn',
            start: '-20% 100%',
            end: '120% 0%',
            scrub: true,
            onEnter() {
                setButtonVisible(true)
            },
            onEnterBack() {
                setButtonVisible(true)
            },
            onLeave() {
                setButtonVisible(false)
            },
            onLeaveBack() {
                setButtonVisible(false)
            },
            onUpdate: () => {
                invalidate()
            }
        });

        return () => {
            diamondScrollTl.kill(true)
        }
    }, [pageReady])

    // ON PAGE LOADED
    useEffect(() => {
        const onPageLoad = () => {
          setPageReady(true);
        }
        if (document.readyState === 'complete') {
          onPageLoad();
        } else {
          window.addEventListener('load', onPageLoad);
          return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    const calculateCursorCoordinates = (e) => {
        cursor.x = e.clientX / width - .5
        cursor.y = e.clientY / height - .5
    }

    const cursor = {}
    cursor.x = 0
    cursor.y = 0

    useEffect(() => {
        window.addEventListener('mousemove', calculateCursorCoordinates)
        return () => window.removeEventListener('mousemove', calculateCursorCoordinates)
    })


    useFrame(() => {
        if (!button.current) return
        if (buttonVisible) invalidate()
        else return
        if (isTouch) return
        buttonContainer.current.rotation.set(
            THREE.MathUtils.lerp(buttonContainer.current.rotation.x, cursor.y * .1 + .05, .1),
            THREE.MathUtils.lerp(buttonContainer.current.rotation.y, cursor.x * .4, .1),
            buttonContainer.current.rotation.z,
        )
    })


    useEffect(() => {
        if (width <= 600) {
            setMultiplierHeight(.00085)
            buttonGroup.current.scale.x = buttonGroup.current.scale.y = buttonGroup.current.scale.z = width * .0008
        } else if (width <= 768) {
            setMultiplierHeight(.00056)
            buttonGroup.current.position.y = .03
        } else if (width <= 1024) {
            setMultiplierHeight(.00075)
            buttonGroup.current.position.y = .03
        } else if (width <= 1366) {
            setMultiplierHeight(.00086)
            buttonGroup.current.position.y = .05
        } else if (width <= 1440) {
            setMultiplierHeight(.0008)
            buttonGroup.current.position.y = .05
        } else if (width > 1040) {
            buttonGroup.current.scale.x = buttonGroup.current.scale.y = buttonGroup.current.scale.z = width * .000375
            buttonGroup.current.position.y = .03
        }
    }, [])

    const colorNegro = '#141515'
    const colorVerde = '#9bcaa6'

    const [color, setColor] = useState()
    useEffect(() => {
        const ctaBlock = document.querySelector('.cta-hablamos')
        if (!ctaBlock) return;
        if (ctaBlock.classList.contains('black')) {
            setColor('verde')
        } else {
            setColor('negro')
        }
    }, [])
    
    const textMaterial = new THREE.MeshBasicMaterial({ color: (color == 'negro') ? colorNegro : colorVerde })


    const geometry = new WireframeGeometry2( nodes.DIA_Button.geometry );

   /* useEffect(() => {
        let matLine = new LineMaterial( {
            color: 0x9bcaa6,
            linewidth: .003, // in pixels
        } );

        let wireframe = new Wireframe( geometry, matLine );
        wireframe.computeLineDistances();
        wireframe.scale.set( 1.1, .45, .3 );
        buttonGroup.current.add( wireframe );
   }, []) */



    return <>

        <group ref={ buttonContainer } position={ [ 0, -height * multiplierHeight, 0 ] } >
            <group ref={ buttonGroup } visible={ buttonVisible } scale={ width * .000466 }>
                <mesh ref={ button } geometry={ nodes.DIA_Button.geometry } scale={ [ 1.1, .45, .3 ] }>
                    <meshBasicMaterial color={ (color == 'negro') ? colorVerde : colorNegro } />
                    <Edges
                        scale={1}
                        threshold={15} 
                        color={ (color == 'negro') ? colorNegro : colorVerde }
                    />
                </mesh>
                <mesh geometry={ nodes.DIA_Button.geometry } scale={ [ 1.1, .45, .3 ] } position={ [ 0.0005, -0.0005, 0.0005 ] }>
                    <MeshDiscardMaterial />
                    <Edges
                        scale={1} 
                        threshold={15} 
                        color={ (color == 'negro') ? colorNegro : colorVerde }
                    />
                </mesh>
                <mesh geometry={ nodes.DIA_Button.geometry } scale={ [ 1.1, .45, .3 ] } position={ [ -0.0005, 0.0005, 0.0005 ] }>
                    <MeshDiscardMaterial />
                    <Edges
                        scale={1}
                        threshold={15} 
                        color={ (color == 'negro') ? colorNegro : colorVerde }
                    />
                </mesh>
                <Text
                    textAlign={ 'center' }
                    anchorX={ 'center' }
                    font={ "/fonts/kento-regular.woff" }
                    material={ textMaterial }
                    position={ [ 0, 0, 0.151 ] }
                    scale={ 0.12 }
                >
                    HABLAMOS
                </Text>

            </group>
        </group>
    </>
}
