import { useEffect, useRef, useState } from 'react';
import { Footer } from './Front';
import { ancla, animaciones, iniScrollSmoother } from './Animations';
import { NavLink } from 'react-router-dom';
import { gsap } from 'gsap';
import Video from './Components/Video';
import Image from './Components/Image';
import WindowHandler from '../Utils/WindowHandler';
import preloadImages from '../Utils/preloadImages';

export function Producto() {

	const [loaded, setLoaded] = useState(false);
  const [canPlayVideos, setCanPlayVideos] = useState(false);
	const [imagesLoaded, setImagesLoaded] = useState(false);
	const [pageReady, setPageReady] = useState(false);
  const isTouch = WindowHandler().isTouch

	useEffect(() => {
		document.body.classList.add('page-producto');
		if (!loaded) return;

		const html = document.querySelector('html');
		if (html) {
			html.classList.add('front');
		}

		iniScrollSmoother();
    setTimeout(function(){
      animaciones('producto', isTouch);
    },0);

	}, [loaded]);

  useEffect(() => {
    if ((canPlayVideos || isTouch) && imagesLoaded && pageReady) {
      setTimeout(() => {
        setLoaded(true)
      }, 0);
    }
  }, [canPlayVideos, imagesLoaded, pageReady])


  useEffect(() => {
    const onPageLoad = () => {
      setPageReady(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

	useEffect(() => {
    const allImg = document.querySelectorAll('img')
    const p = []
    allImg.forEach((img) => {
      p.push(img.src)
    })
    preloadImages(p, () => setImagesLoaded(true))
	}, []);

	var carrouselActual = 0;
	const carrousel = (e, n) => {
		e.preventDefault();

		if (carrouselActual + n >= 0 && carrouselActual + n <= 2) {
			var AniCarr = gsap.timeline({
				defaults: {
					duration: 1,
					ease: 'power2.out'
				}
			});
			carrouselActual = carrouselActual + n;

			let tr = carrouselActual * -75;
			if (window.innerWidth <= 1024) {
				tr = carrouselActual * -80;
			}
      if (window.innerWidth <= 600) {
				tr = carrouselActual * -100;
			}

			AniCarr.to('.carrousel__inner', { x: tr + 'vw' }, '0');
			AniCarr.play();
		}
		return false;
	};

  const anclaLateral = (event) => {
    event.preventDefault();
    let st = (window.innerHeight * 5) + window.innerWidth + ( window.innerHeight * 0.5);
    if (window.innerWidth <= 1024) {
      st = (window.innerHeight * 5) + window.innerWidth;
    }
    gsap.to(window, {
      scrollTo: st,
      ease: 'power2.out'
    });

    return false;

  };

	return (
		<>
<div id="smooth-wrapper">
  <div id="smooth-content">
   
    <div className="header-pin">
      <div className="header header2 header-ani header__producto">
          <div className="header__data">
            <div className="pieza-header-container">
              <div className="pieza pieza-header">
                <span className="pieza__line pieza__1"></span>
                <span className="pieza__line pieza__2"></span>
                <span className="pieza__line pieza__3"></span>
                <span className="pieza__line pieza__4"></span>
                <span className="pieza__rombo pieza__5"></span>
              </div>
            </div>

            <div className="header__pretitulo ani_ini_mask_fade" >
            <span data-delay="1.2" >EL PRODUCTO</span>
            </div>

            <div className='header__titulo_ani --producto'>
              <div className='header__titulo_ani_1'><div className='tit'><span className='linet linet1'>UN TOQUE</span><span className='linet linet2'>DE EXCLUSIVIDAD</span><span className='linet linet3'>CONSCIENTE</span></div></div>
              <div className='header__titulo_ani_2'><div className='tit'><span className='linet linet1'>UN TOQUE</span><span className='linet linet2'>DE EXCLUSIVIDAD</span><span className='linet linet3'>CONSCIENTE</span></div></div>
              <div className='header__titulo_ani_3'><div className='tit'>
                <span className='linet linet1'><span className='ocu'>UN </span>TOQ<span className='ocu'>UE</span></span>
                <span className='linet linet2'>DE EXCLUSI<span className='ocu'>VIDAD</span></span>
                <span className='linet linet3'><span className='ocu'>CO</span>NSCIE<span className='ocu'>NTE</span></span>
                </div></div>
            </div>

            <div className="header__ani_center">
              <div className='header__ani_rombo'></div>
              <div className="header__ani">
                <img src="images/animaciones/header_producto/DIA_22000.png" id='header_img' className='header__ani_img' />
              </div>
            </div>
          </div>

        <div className="cita cita-header --producto">
          <div className="cita__title ani_ini_mask">
            <span data-delay="1.6">Exclusividad consciente</span>
          </div>
          <div className="cita__cita ani_ini_line" data-delay="1.6">Descubre la perfecta combinación entre exclusividad y conciencia con los diamantes de laboratorio</div>
        </div>

        <div className="header__arrow">
          <a href="#" onClick={(e)=> ancla('cards')}
            className="btn__rombo btn__rombo_svg btn__rombo_svg-negro">
            <span className="btn__rombo_cont"></span>
            <span className="btn__rombo_txt">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.085 29.766">
                  <g fill="none" stroke="#141515" strokeMiterlimit="10" data-name="Grupo 6425">
                    <path d="M.779 13.804h13.527L7.542 28.566Z" data-name="Trazado 37419" />
                    <path d="M7.543 13.804V0" data-name="Línea 43" />
                  </g>
                </svg>
              </span>
            </span>
          </a>
        </div>
      </div>
    </div>



    <div className="fondo__verde fondo__verde-cards">
      <div className="cards" id="cards">
        <div className="cards__container">
          <div className="card card1">
            <div className="card__fondo">
              <svg xmlns="http://www.w3.org/2000/svg" className='card__fondo_desktop' viewBox="0 0 1410.417 619.022"><path fill="#81bc8f" d="M0 540.216V77.26L81.891 0h839.958v.055L1328.106 0l82.311 78.8v462.96l-81.891 77.257H488.563v-.051l-406.258.056Z" data-name="Unión 26" /></svg>
              <svg xmlns="http://www.w3.org/2000/svg" className='card__fondo_movil' viewBox="0 0 335 632.225"><g fill="#81bc8f" data-name="Grupo 7524"><path d="M292.354 632.224 335 587.681l-.061-454.671L0 133.337v454.569l41.809 44.318Z" data-name="Trazado 37755"/><path d="M42.646 0 0 44.543l.061 454.671L335 498.887V44.318L293.191 0Z" data-name="Trazado 37756"/></g></svg>
            </div>
            <div className="card__img card__img_mismacalidad">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 578.924 336.291"><path fill="#9bcaa6" d="M0 168.147 289.464 0l289.46 168.145-289.46 168.146Z" data-name="Unión 4"/></svg>
              <Video
                src="DIA_23"
                poster="images/DIA_2300.png"
                loop={ true }
              />
            </div>
            <div className="card__data">
              <div className="card__data_number">01</div>
              <div className="card__data_title">MISMA CALIDAD</div>
              <div className="card__data_txt">
                Los diamantes de laboratorio, con propiedades idénticas a
                los extraídos de minas, son una reserva de valor e
                inversión equivalente en el ámbito del lujo.
              </div>
            </div>
          </div>

          <div className="card card2">
            <div className="card__fondo">
              <svg xmlns="http://www.w3.org/2000/svg" className='card__fondo_desktop' viewBox="0 0 1410.417 619.022"><path fill="#81bc8f" d="M0 540.216V77.26L81.891 0h839.958v.055L1328.106 0l82.311 78.8v462.96l-81.891 77.257H488.563v-.051l-406.258.056Z" data-name="Unión 26" /></svg>
              <svg xmlns="http://www.w3.org/2000/svg" className='card__fondo_movil' viewBox="0 0 335 632.225"><g fill="#81bc8f" data-name="Grupo 7524"><path d="M292.354 632.224 335 587.681l-.061-454.671L0 133.337v454.569l41.809 44.318Z" data-name="Trazado 37755"/><path d="M42.646 0 0 44.543l.061 454.671L335 498.887V44.318L293.191 0Z" data-name="Trazado 37756"/></g></svg>
            </div>
            <div className="card__img">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 578.924 336.291"><path fill="#9bcaa6" d="M0 168.147 289.464 0l289.46 168.145-289.46 168.146Z" data-name="Unión 4"/></svg>
              <Video
                src="DIA_24"
                poster="images/DIA_2400.png"
                loop={ true }
              />
            </div>
            <div className="card__data">
              <div className="card__data_number">02</div>
              <div className="card__data_title">
                INVERSIÓN SOSTENIBLE Y ÉTICA
              </div>
              <div className="card__data_txt">
                Los diamantes de laboratorio son igualmente valiosos y
                éticamente sostenibles, atrayendo a más personas
                preocupadas por el impacto ambiental de la minería de
                joyas.
              </div>
            </div>
          </div>

          <div className="card card3">
            <div className="card__fondo">
              <svg xmlns="http://www.w3.org/2000/svg" className='card__fondo_desktop' viewBox="0 0 1410.417 619.022"><path fill="#81bc8f" d="M0 540.216V77.26L81.891 0h839.958v.055L1328.106 0l82.311 78.8v462.96l-81.891 77.257H488.563v-.051l-406.258.056Z" data-name="Unión 26" /></svg>
              <svg xmlns="http://www.w3.org/2000/svg" className='card__fondo_movil' viewBox="0 0 335 632.225"><g fill="#81bc8f" data-name="Grupo 7524"><path d="M292.354 632.224 335 587.681l-.061-454.671L0 133.337v454.569l41.809 44.318Z" data-name="Trazado 37755"/><path d="M42.646 0 0 44.543l.061 454.671L335 498.887V44.318L293.191 0Z" data-name="Trazado 37756"/></g></svg>
            </div>
            <div className="card__img">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 578.924 336.291"><path fill="#9bcaa6" d="M0 168.147 289.464 0l289.46 168.145-289.46 168.146Z" data-name="Unión 4"/></svg>
              <Video
                src="DIA_25"
                poster="images/DIA_2500.png"
                loop={ true }
              />
            </div>
            <div className="card__data">
              <div className="card__data_number">03</div>
              <div className="card__data_title">ALTERNATIVA EN ALZA</div>
              <div className="card__data_txt">
                El mercado de los diamantes de laboratorio está en
                crecimiento, alcanzando un valor de mercado de 22,45 mil
                millones de dólares en 2022 y proyectando llegar a 37,32
                mil millones de dólares para 2028.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contenedor__scroll_lateral">
        <div className="contenedor__scroll_lateral-seccion">
          <div className="bloque__vision__txtbig_container  bloque__vision__txtbig_container_producto">
            <div className="bloque__vision__txtbig_container-data">
            <div className="bloque__vision__txtbig bloque__vision__txtbig-max ani-scroll-enter">
               Explora la distinción de nuestros diamantes éticos
            </div>
            <div className="bloque__vision__txtbig-line">
              <div className="line line-h-l-r2"></div>

              <a href="#" onClick={anclaLateral} className="btn__rombo btn__rombo_svg btn__rombo_svg-verde btn__rombo-vision">
                <span className="btn__rombo_cont"></span>
                <span className="btn__rombo_txt">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.188 31.242">
                      <g fill="none" stroke="#141515" strokeMiterlimit="10" strokeWidth="1.5" data-name="Grupo 7607">
                        <path d="M18.705 1.564v28.114L1.198 15.62Z" data-name="Trazado 37421" />
                        <path d="M18.706 15.619h40.482" data-name="Línea 44" />
                      </g>
                    </svg>
                  </span>
                </span>
              </a>
            </div>
            <div className="bloque__vision__txtbig-cols">
              <div>
                <span>PRINCIPALES CARACTERÍSTICAS</span>
              </div>
            </div>
            </div>
          </div>
        </div>

        <div className="contenedor__scroll_lateral-seccion contenedor__scroll_lateral-data">
          <div className="csls__row">
            <div className="csls__col csls__col-sostenibilidad">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 803.189 693.893"><path fill="#81bc8f" d="M401.595 0 803.19 693.893H0Z" data-name="Polígono 85"/></svg>
              <Video
                src="DIA_26"
                poster="images/DIA_2600.png"
                loop={ true }
              />
            </div>
            <div className="csls__col">
              <div className="csls__col_data">
                <div className="csls__col_pre">Sostenibilidad</div>
                <div className="csls__col_tit">
                Nuestros diamantes éticos creados en laboratorio se basan en tres pilares fundamentales: medioambiental, social y ética, que impulsan nuestra visión de un futuro más consciente y responsable.
                </div>
                <div className="csls__col_txt">
                  <p>
                    <b>Sostenibilidad medioambiental:</b> Reducimos la huella
                    ambiental y protegemos el ecosistema al evitar la
                    extracción de diamantes de la Tierra.
                  </p>
                  <p>
                    <b>Sostenibilidad social:</b> Promovemos el desarrollo
                    sostenible, evitando conflictos y violaciones de
                    derechos humanos. Generamos empleos cualificados y
                    respetamos a las comunidades locales y al medio
                    ambiente.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contenedor__scroll_lateral-seccion contenedor__scroll_lateral-data">
          <div className="csls__row">
            <div className="csls__col csls__col-unicidad">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 581.643 687.123"><path fill="#81bc8f" d="M96.761 687.122 0 606.224V80.9L96.761 0h388.114l96.768 80.9v525.324l-96.768 80.9Z" data-name="Unión 56"/></svg>
              <Video
                src="DIA_27"
                poster="images/DIA_2700.png"
                loop={ true }
              />
            </div>
            <div className="csls__col">
              <div className="csls__col_data">
                <div className="csls__col_pre">Unicidad</div>
                <div className="csls__col_tit">
                  Únicos y especiales, creados a partir de semillas de
                  carbono obtenidas en colaboración con marcas y
                  personalidades destacadas.
                </div>
                <div className="csls__col_txt">
                  <p>
                    Cada diamante es único en el mundo y representa una
                    conexión especial con marcas y personalidades
                    reconocidas, ofreciendo una experiencia exclusiva y
                    significativa a sus propietarios.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contenedor__scroll_lateral-seccion contenedor__scroll_lateral-data">
          <div className="csls__row">
            <div className="csls__col csls__col-autenticidad">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 737.463 737.463"><path fill="#81bc8f" d="M0 368.732 368.731.001l368.732 368.731L368.73 737.463z" data-name="Rectángulo 2708"/></svg>
              <Video
                src="DIA_23"
                poster="images/DIA_2300.png"
                loop={ true }
              />
            </div>
            <div className="csls__col">
              <div className="csls__col_data">
                <div className="csls__col_pre">Autenticidad</div>
                <div className="csls__col_tit">
                  Un diamante Dianelum comparte el mismo ADN que un
                  diamante extraído de la mina
                </div>
                <div className="csls__col_txt">
                  <p>
                    y ambos requieren la aprobación y certificación de un
                    instituto gemológico prestigioso para ser considerados
                    diamantes auténticos.
                  </p>
                  <p>
                    Nuestros diamantes sostenibles están certificados por
                    el IGI, líder mundial en gemología, garantizando la
                    calidad y autenticidad de nuestras joyas responsables.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="fondo__negro headerVerde">
      <div className="bloque__txtc bloque__txtc-producto">
        <div className="bloque__txtc__figura">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.224 107.224" className="ani-scroll-rotate">
            <g fill="none" stroke="#9bcaa6" strokeLinejoin="round" data-name="Grupo 7609">
              <path d="M53.612.499 16.056 16.055.5 53.611l15.556 37.556 37.556 15.556 37.556-15.556 15.556-37.556-15.556-37.556Z" data-name="Trazado 37434" />
              <path strokeWidth="1.00023" d="M8.276 72.39 34.854 8.269l64.12 26.578-26.578 64.12z" data-name="Rectángulo 70" />
              <path d="M8.276 34.831 72.388 8.275l26.556 64.113-64.112 26.556z" data-name="Rectángulo 71" />
              <path d="M42.731 53.612h21.761" data-name="Línea 56" />
              <path d="M53.611 64.493V42.732" data-name="Línea 57" />
            </g>
          </svg>
        </div>
        <div className="bloque__txtc__pretitulo ani_scroll_mask">
          <span>NUESTRO PRODUCTO</span>
        </div>
        <div className="bloque__txtc__titulo">
          <h2 className="ani-scroll-enter">
            COMPARATIVA CON DIAMANTE DE MINA
          </h2>
        </div>
      </div>

      <div className="tabla">
        <div className="row">
          <div className="col col-header col col-txt"></div>
          <div className="col col-header">DIAMANTE DE MINA</div>
          <div className="col col-header">DIAMANTE DE LABORATORIO</div>
        </div>

        <div className="row">
          <div className="col col-txt">
            Siguen los estandares de las 4 cs ( corte, color, claridad y
            carat)
          </div>
          <div className="col col-data">SI</div>
          <div className="col col-data">SI</div>
        </div>

        <div className="row">
          <div className="col col-txt">
            Dureza, brillo y resistencia a los arañazos
          </div>
          <div className="col col-data">SI</div>
          <div className="col col-data">SI</div>
        </div>

        <div className="row">
          <div className="col col-txt">Calidad y apariencia</div>
          <div className="col col-data">IGUALES</div>
          <div className="col col-data">IGUALES</div>
        </div>

        <div className="row">
          <div className="col col-txt">
            Sostenible y respetuosa con el medio ambiente, sin
            deforestación
          </div>
          <div className="col col-data">NO</div>
          <div className="col col-data">SI</div>
        </div>

        <div className="row">
          <div className="col col-txt">
            Evitan desplazamientos y contaminación, preservando la calidad
            de vida de las comunidades locales
          </div>
          <div className="col col-data">NO</div>
          <div className="col col-data">SI</div>
        </div>

        <div className="row">
          <div className="col col-txt">Altera los ecosistemas</div>
          <div className="col col-data">SI</div>
          <div className="col col-data">NO</div>
        </div>

        <div className="row">
          <div className="col col-txt">
            Generan residuos ambientales significativos
          </div>
          <div className="col col-data">SI</div>
          <div className="col col-data">NO</div>
        </div>

        <div className="row">
          <div className="col col-txt">
            Requieren un alto consumo de agua
          </div>
          <div className="col col-data">SI</div>
          <div className="col col-data">NO</div>
        </div>
      </div>
    </div>

    <div className="fondo__verde">
      <div className="bloque__triangulo bloque__triangulo-producto">
        <div className="bloque__triangulo__titulo btt1">PROCESO</div>
        <div className="bloque__triangulo__titulo btt2">PRODUCTIVO</div>

        <div className="bloque__triangulo__tri bloque__triangulo__tri-pro">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 737.463 737.463"><path fill="#81bc8f" d="M0 368.732 368.731.001l368.732 368.731L368.73 737.463z" data-name="Rectángulo 2708"/></svg>
          <Video
            src="DIA_29"
            poster="images/DIA_2900.png"
            loop={ true }
          />
        </div>

        <div className="bloque__triangulo__txt bloque__triangulo__txt-producto">
          <span>De la materia prima al brillo<br /> deslumbrante</span>
        </div>
        <div className="bloque__triangulo__medium kentoMedium ani-scroll-enter">
          LA PRODUCCIÓN DE DIAMANTES EN LABORATORIO ES UN PROCESO
          COMPLEJO, QUE REQUIERE TECNOLOGÍA AVANZADA Y PRECISIÓN EN CADA
          ETAPA DEL PROCESO.
        </div>
      </div>

      <div className="bloque__scroll__later">
        <div className="bloque__sl__fixed">
          <div className="bloque__sl__mask">
            <div className="bloque__sl__rombo"></div>
            <Video
              id="bloque__sl01_img"
              src="DIA_30"
              poster="images/DIA_3000.png"
              loop={ true }
            />
             <Video
              id="bloque__sl02_img"
              src="DIA_31"
              poster="images/DIA_3100.png"
              loop={ true }
            />
            <Video
              id="bloque__sl03_img"
              src="DIA_32"
              poster="images/DIA_3200.png"
              loop={ true }
            />
            <Video
              id="bloque__sl04_img"
              src="DIA_33"
              poster="images/DIA_3300.png"
              loop={ true }
            />
            <Video
              id="bloque__sl05_img"
              src="DIA_34"
              poster="images/DIA_3400.png"
              loop={ true }
            />
          </div>
        </div>

        <div className="bloque__sl__pantalla" id="bloque__sl01">
          <div className="bloque__sl__data_img_movil">
            <Image src='images/materia-prima-movil.png' alt='Materia Prima' />
          </div>
          <div className="bloque__sl__data">
            <div className="bloque__sl__data_number">01</div>
            <div className="bloque__sl__data_title">
              Seleccionando la materia prima
            </div>
            <div className="bloque__sl__data_cont">
              Se selecciona cuidadosamente una materia prima exclusiva de
              carbono de alta pureza para la producción de nuestros
              diamantes.
            </div>
          </div>
        </div>

        <div className="bloque__sl__pantalla" id="bloque__sl02">
          <div className="bloque__sl__data_img_movil">
            <Image src='images/pulido-movil.png' alt='Obteniendo el diamante' />

          </div>
          <div className="bloque__sl__data">
            <div className="bloque__sl__data_number">02</div>
            <div className="bloque__sl__data_title">
              Obteniendo el diamante
            </div>
            <div className="bloque__sl__data_cont">
              Utilizando alta presión y alta temperatura, transformamos la
              materia prima de carbono en diamantes, replicando las
              condiciones naturales de formación.
            </div>
          </div>
        </div>

        <div className="bloque__sl__pantalla" id="bloque__sl03">
          <div className="bloque__sl__data_img_movil">
            <Image src='images/afinado-movil.png' alt='Afinando la perfección' />
          </div>
          <div className="bloque__sl__data">
            <div className="bloque__sl__data_number">03</div>
            <div className="bloque__sl__data_title">
              Afinando la perfección
            </div>
            <div className="bloque__sl__data_cont">
              Cada diamante se evalúa minuciosamente para garantizar su
              calidad en términos de impurezas y brillo, ofreciendo
              niveles de excelencia variados.
            </div>
          </div>
        </div>

        <div className="bloque__sl__pantalla" id="bloque__sl04">
          <div className="bloque__sl__data_img_movil">
            <Image src='images/obtencion-movil.png' alt='Puliendo y Tallando' />
          </div>
          <div className="bloque__sl__data">
            <div className="bloque__sl__data_number">04</div>
            <div className="bloque__sl__data_title">
              Puliendo y Tallando
            </div>
            <div className="bloque__sl__data_cont">
              Los diamantes de laboratorio son cuidadosamente pulidos y
              tallados para lograr su máximo brillo y belleza.
            </div>
          </div>
        </div>

        <div className="bloque__sl__pantalla" id="bloque__sl05">
        <div className="bloque__sl__data_img_movil">
            <Image src='images/garantia-movil.png' alt='Garantía de calidad' />
          </div>
          <div className="bloque__sl__data">
            <div className="bloque__sl__data_number">05</div>
            <div className="bloque__sl__data_title">
              Garantía de calidad
            </div>
            <div className="bloque__sl__data_cont">
              Los diamantes de laboratorio con un peso mínimo de 0,50
              quilates son certificados individualmente por el Instituto
              Gemológico Internacional (IGI), garantizando su autenticidad
              y calidad excepcional.
            </div>
          </div>
        </div>
      </div>

      <div className="bloque__product_txt">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.224 107.224" className="ani-scroll-rotate">
          <g fill="none" stroke="#1C1E1D" strokeLinejoin="round" data-name="Grupo 7609">
            <path d="M53.612.499 16.056 16.055.5 53.611l15.556 37.556 37.556 15.556 37.556-15.556 15.556-37.556-15.556-37.556Z" data-name="Trazado 37434" />
            <path strokeWidth="1.00023" d="M8.276 72.39 34.854 8.269l64.12 26.578-26.578 64.12z" data-name="Rectángulo 70" />
            <path d="M8.276 34.831 72.388 8.275l26.556 64.113-64.112 26.556z" data-name="Rectángulo 71" />
            <path d="M42.731 53.612h21.761" data-name="Línea 56" />
            <path d="M53.611 64.493V42.732" data-name="Línea 57" />
          </g>
        </svg>
        <div className="bloque__product_txt_data ani-scroll-enter">
          Un material versátil que impulsa la innovación en múltiples
          industrias
        </div>
      </div>

       <div className="bloque__listado-producto">
        <div className="bloque__listado">
          <div className="bloque__listado_item bloque__listado_item_hidden_movil">
            <div className="bloque__listado_item-linea">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.389 29.389">
                <path fill="#141515" d="m14.695 0 14.694 14.694L14.695 29.39.001 14.694z" data-name="Rectángulo 2404"></path>
              </svg>
              <span className="line"></span>
            </div>
          </div>

          <div className="bloque__listado_item" id="img__industria">
            <div className="bloque__listado_item-img-movil">
              <Image src="images/industria-movil.png" />
            </div>
            <div className="bloque__listado_item-num">
              <span>01</span>
            </div>
            <div className="bloque__listado_item-titulo">INDUSTRIA</div>
            <div className="bloque__listado_item-txt">
              En el ámbito de la industria, es ampliamente utilizado en
              puntas de taladros y herramientas de corte debido a su
              dureza y durabilidad excepcionales
            </div>
            <div className="bloque__listado_item-linea">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.389 29.389">
                <path fill="#141515" d="m14.695 0 14.694 14.694L14.695 29.39.001 14.694z" data-name="Rectángulo 2404"></path>
              </svg>
              <span className="line"></span>
            </div>
          </div>

          <div className="bloque__listado_item" id="img__electronica">
            <div className="bloque__listado_item-img-movil">
              <Image src="images/electronica-movil.png" />
            </div>
            <div className="bloque__listado_item-num">
              <span>02</span>
            </div>
            <div className="bloque__listado_item-titulo">ELECTRÓNICA</div>
            <div className="bloque__listado_item-txt">
              En el ámbito de la electrónica, es utilizado en la
              fabricación de componentes para tecnologías avanzadas debido
              a su alta conductividad térmica y resistencia a altas
              temperaturas.
            </div>
            <div className="bloque__listado_item-linea">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.389 29.389">
                <path fill="#141515" d="m14.695 0 14.694 14.694L14.695 29.39.001 14.694z" data-name="Rectángulo 2404"></path>
              </svg>
              <span className="line"></span>
            </div>
          </div>

          <div className="bloque__listado_item" id="img__salud">
            <div className="bloque__listado_item-img-movil">
              <Image src="images/salud-movil.png" />
            </div>
            <div className="bloque__listado_item-num">
              <span>03</span>
            </div>
            <div className="bloque__listado_item-titulo">
              SALUD Y BIOTECNOLOGÍA
            </div>
            <div className="bloque__listado_item-txt">
              En el campo de la salud y la biotecnología, se usa en
              instrumentos quirúrgicos por su resistencia y
              biocompatibilidad, siendo una opción segura en
              procedimientos médicos.
            </div>
            <div className="bloque__listado_item-linea">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.389 29.389">
                <path fill="#141515" d="m14.695 0 14.694 14.694L14.695 29.39.001 14.694z" data-name="Rectángulo 2404"></path>
              </svg>
              <span className="line"></span>
            </div>
          </div>

          <div className="bloque__listado_item" id="img__militar">
            <div className="bloque__listado_item-img-movil">
              <Image src="images/militar-movil.png" />
            </div>
            <div className="bloque__listado_item-num">
              <span>04</span>
            </div>
            <div className="bloque__listado_item-titulo">MILITAR</div>
            <div className="bloque__listado_item-txt">
              En el ámbito militar, se usa en tecnologías avanzadas y
              aplicaciones de seguridad debido a sus propiedades ópticas y
              su extrema dureza.
            </div>
            <div className="bloque__listado_item-linea">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.389 29.389">
                <path fill="#141515" d="m14.695 0 14.694 14.694L14.695 29.39.001 14.694z" data-name="Rectángulo 2404"></path>
              </svg>
              <span className="line"></span>
            </div>
          </div>

          <div className="bloque__listado_item" id="img__construccion">
            <div className="bloque__listado_item-img-movil">
              <Image src="images/construccion-movil.png" />
            </div>
            <div className="bloque__listado_item-num">
              <span>05</span>
            </div>
            <div className="bloque__listado_item-titulo">
              CONSTRUCCIÓN
            </div>
            <div className="bloque__listado_item-txt">
              En el campo de la construcción, se utiliza en la reparación
              de carreteras y caminos debido a su durabilidad excepcional
              y larga vida útil.
            </div>
            <div className="bloque__listado_item-linea">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.389 29.389">
                <path fill="#141515" d="m14.695 0 14.694 14.694L14.695 29.39.001 14.694z" data-name="Rectángulo 2404"></path>
              </svg>
              <span className="line"></span>
            </div>
          </div>

          <div className="bloque__listado_item" id="img__energia">
            <div className="bloque__listado_item-img-movil">
              <Image src="images/energia-movil.png" />
            </div>
            <div className="bloque__listado_item-num">
              <span>06</span>
            </div>
            <div className="bloque__listado_item-titulo">ENERGÍA</div>
            <div className="bloque__listado_item-txt">
              En el ámbito de la energía, se está investigando su uso en
              el desarrollo de baterías verdes con energía ilimitada, lo
              que podría tener un impacto significativo en la eficiencia y
              sostenibilidad energética.
            </div>
            <div className="bloque__listado_item-linea">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.389 29.389">
                <path fill="#141515" d="m14.695 0 14.694 14.694L14.695 29.39.001 14.694z" data-name="Rectángulo 2404"></path>
              </svg>
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div> 
            
            
       <div className="bloque__txtc bloque__txtc-inversion">
        <div className="pieza pieza__grande ani_scroll_pieza_grande">
          <span className="pieza__line pieza__1"></span>
          <span className="pieza__line pieza__2"></span>
          <span className="pieza__line pieza__3"></span>
          <span className="pieza__line pieza__4"></span>
          <span className="pieza__rombo pieza__5"></span>
        </div>

        <div className="bloque__txtc__pretitulo ani_scroll_mask">
          <span>PORTFOLIO</span>
        </div>
        <div className="bloque__txtc__titulo">
          <h2 className="ani-scroll-enter">
            NUESTROS PROYECTOS ACTUALES
          </h2>
        </div>
        <div className="bloque__txtc__titulo__rombodeco ani_scroll_rombodeco">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.389 29.389">
            <path fill="#141515" d="m14.695 0 14.694 14.694L14.695 29.39.001 14.694z" data-name="Rectángulo 2404"></path>
          </svg>
        </div>
        <div className="cita">
          <div className="cita__title ani_scroll_mask">
            <span>Partner de confianza</span>
          </div>
          <div className="cita__cita ani_scroll_fade">
            A través de nuestro partner Brilianto nuestros diamantes han sido utilizados en diferentes proyectos.<br /><br /><br />
            <a href="https://www.brilianto.com/" target="_blank" className="btn__ver btn__ver-visita btn__ver-visita-verde" color="verde" id="button-3d-producto-1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 273.727 62.066"><g fill="none" stroke="#141515" data-name="Grupo 3017"><path strokeLinejoin="round" d="M252.355.5H21.372L.5 12.713v36.64l20.872 12.213h230.983l20.872-12.213v-36.64Z" data-name="Trazado 37445"/><path strokeLinejoin="round" d="m.5 49.353 136.363 12.213 136.364-12.213" data-name="Trazado 37447"/><path strokeLinejoin="round" d="M273.227 12.713 136.864.5.5 12.713" data-name="Trazado 37448"/><path d="M19.564.5v61.066" data-name="Línea 107"/><path d="M254.163.5v61.066" data-name="Línea 108"/></g></svg>
                <span>VISITA BRILIANTO</span>
              </a>
            </div>
          </div>
        </div>
      </div>
     

      {/* <div className="bloque__carrousel">
        <div className="carrousel">
          <div className="carrousel__inner">
            <div className="carrousel__item">
              <div className="diapo">
                <div className="diapo__num">01</div>
                <div className="diapo__img">
                  <img src="images/barcelona.png" />
                </div>
                <div className="diapo__data">
                  <div className="diapo__data_cont">
                    <div className="diapo__data_cont_title">
                      F.C.Barcelona
                    </div>
                    <div className="diapo__data_cont_cont">
                      <a href="https://www.brilianto.com/colecciones/fcbarcelona/" target="_blank" className="btn__ver">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.846 56">
                          <g fill="none" stroke="#141515" data-name="Grupo 3017">
                            <path strokeLinejoin="round" d="M153.654.5H13.192L.5 11.5v33l12.692 11h140.462l12.692-11v-33Z" data-name="Trazado 37445" />
                            <path strokeLinejoin="round" d="m.5 42.599 82.923 12.9 82.923-12.9" data-name="Trazado 37447" />
                            <path strokeLinejoin="round" d="M166.346 13.401 83.423.501.5 13.401" data-name="Trazado 37448" />
                            <path d="M15.636.5v55" data-name="Línea 107" />
                            <path d="M151.21.5v55" data-name="Línea 108" />
                          </g>
                        </svg>
                        <span>VER</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="carrousel__item">
              <div className="diapo">
                <div className="diapo__num">02</div>
                <div className="diapo__img">
                  <img src="images/atletico.png" />
                </div>
                <div className="diapo__data">
                  <div className="diapo__data_cont">
                    <div className="diapo__data_cont_title">
                      Atlético de Madrid
                    </div>
                    <div className="diapo__data_cont_cont">
                      <a href="https://www.brilianto.com/colecciones/atletico-de-madrid/" target="_blank" className="btn__ver">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.846 56">
                          <g fill="none" stroke="#141515" data-name="Grupo 3017">
                            <path strokeLinejoin="round" d="M153.654.5H13.192L.5 11.5v33l12.692 11h140.462l12.692-11v-33Z" data-name="Trazado 37445" />
                            <path strokeLinejoin="round" d="m.5 42.599 82.923 12.9 82.923-12.9" data-name="Trazado 37447" />
                            <path strokeLinejoin="round" d="M166.346 13.401 83.423.501.5 13.401" data-name="Trazado 37448" />
                            <path d="M15.636.5v55" data-name="Línea 107" />
                            <path d="M151.21.5v55" data-name="Línea 108" />
                          </g>
                        </svg>
                        <span>VER</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="carrousel__item">
              <div className="diapo">
                <div className="diapo__num">03</div>
                <div className="diapo__img">
                  <img src="images/espanol.png" />
                </div>
                <div className="diapo__data">
                  <div className="diapo__data_cont">
                    <div className="diapo__data_cont_title">
                      RCD Español
                    </div>
                    <div className="diapo__data_cont_cont">
                      <div className="diapo__data_cont_cont">
                        <a href="https://www.brilianto.com/colecciones/rcd-espanyol/" target="_blank" className="btn__ver">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.846 56">
                            <g fill="none" stroke="#141515" data-name="Grupo 3017">
                              <path strokeLinejoin="round" d="M153.654.5H13.192L.5 11.5v33l12.692 11h140.462l12.692-11v-33Z" data-name="Trazado 37445" />
                              <path strokeLinejoin="round" d="m.5 42.599 82.923 12.9 82.923-12.9" data-name="Trazado 37447" />
                              <path strokeLinejoin="round" d="M166.346 13.401 83.423.501.5 13.401" data-name="Trazado 37448" />
                              <path d="M15.636.5v55" data-name="Línea 107" />
                              <path d="M151.21.5v55" data-name="Línea 108" />
                            </g>
                          </svg>
                          <span>VER</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="carrousel__controller">
          <a href="#" onClick={(e)=> carrousel(e, -1)}
            className="btn__rombo btn__rombo_svg btn__rombo_svg-negro carrousel__controller-left">
            <span className="btn__rombo_cont"></span>
            <span className="btn__rombo_txt">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.591 24.319">
                  <g fill="none" stroke="#141515" strokeMiterlimit="10" data-name="Grupo 7596">
                    <path d="M14.644 1.042v22.237L.798 12.158Z" data-name="Trazado 37421" />
                    <path d="M14.644 12.156h12.947" data-name="Línea 44" />
                  </g>
                </svg>
              </span>
            </span>
          </a>

          <a href="#" onClick={(e)=> carrousel(e, 1)}
            className="btn__rombo btn__rombo_svg btn__rombo_svg-negro carrousel__controller-right">
            <span className="btn__rombo_cont"></span>
            <span className="btn__rombo_txt">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.591 24.319">
                  <g fill="none" stroke="#141515" strokeMiterlimit="10" data-name="Grupo 7596">
                    <path d="M14.644 1.042v22.237L.798 12.158Z" data-name="Trazado 37421"></path>
                    <path d="M14.644 12.156h12.947" data-name="Línea 44"></path>
                  </g>
                </svg>
              </span>
            </span>
          </a>
        </div>
      </div> */}

      <div className="bloque__hablamos bloque__hablamos-producto cta-hablamos">
        <div className="bloque_hablamos_txt ">Estamos aquí para resolver tus dudas.</div>
        <div className="bloque__hablamos_cont_btn bloque__hablamos_cont_btn-negro">
          <NavLink to="/contacto" className="btn_hablamos">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1436.96 453.688"><g stroke="#9bcaa6" data-name="Grupo 7722"><g fill="none" strokeLinejoin="round" strokeWidth="1" data-name="Grupo 6307"><path d="M1436.21 338.157V115.538L1321.428.75H115.532L.75 115.538v222.619l114.782 114.782h1205.9Z" data-name="Trazado 37422"/><path d="M.752 115.535 718.479.753l717.733 114.782" data-name="Trazado 37423"/><path d="m.752 338.157 717.727 114.782 717.733-114.782" data-name="Trazado 37424"/><path d="M115.53.751V452.94" data-name="Línea 45"/><path d="M1321.431.751V452.94" data-name="Línea 46"/></g><g data-name="Grupo 6309"><path fill="none" strokeMiterlimit="10" d="M744.237 74.461h-53.114" data-name="Línea 63"/><path fill="none" strokeMiterlimit="10" d="M745.06 74.461h-53.114" data-name="Línea 64"/><path fill="none" strokeMiterlimit="10" d="M717.681 101.019V47.905" data-name="Línea 65"/><path fill="#9bcaa6" d="m710.745 74.463 6.936-6.936 6.936 6.936-6.936 6.936z" data-name="Rectángulo 75"/><path fill="none" strokeMiterlimit="10" d="m710.745 74.463 6.936-6.936 6.936 6.936-6.936 6.936z" data-name="Rectángulo 76"/></g><g data-name="Grupo 6310"><path fill="none" strokeMiterlimit="10" d="M744.236 388.808h-53.114" data-name="Línea 63"/><path fill="none" strokeMiterlimit="10" d="M745.059 388.808h-53.114" data-name="Línea 64"/><path fill="none" strokeMiterlimit="10" d="M717.68 415.366v-53.114" data-name="Línea 65"/><path fill="#96c495" d="m710.744 388.81 6.936-6.936 6.936 6.936-6.936 6.936z" data-name="Rectángulo 75"/><path fill="#9bcaa6" strokeMiterlimit="10" d="m710.744 388.81 6.936-6.936 6.936 6.936-6.936 6.936z" data-name="Rectángulo 76"/></g></g></svg>
            <span>HABLAMOS</span>
          </NavLink>
        </div>
      </div>
    <Footer  tipo="headerVerde" />

    </div>
    
  </div>

<div className="bloque__listado_hover">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 616 357">
    <path fill="#81BC8F" d="M0 178.5 308 0l308 178.5L308 357Z" data-name="Unión 1" />
  </svg>
  
  <Video
    id="img__industria_img"
    className="bloque__listado_hover_img"
    src="DIA_35"
    poster="/images/DIA_3500.png"
    loop={ true }
  />
  <Video
    id="img__electronica_img"
    className="bloque__listado_hover_img"
    src="DIA_36"
    poster="/images/DIA_3600.png"
    loop={ true }
  />
  <Video
    id="img__salud_img"
    className="bloque__listado_hover_img"
    src="DIA_37"
    poster="/images/DIA_3700.png"
    loop={ true }
  />
  <Video
    id="img__militar_img"
    className="bloque__listado_hover_img"
    src="DIA_38"
    poster="/images/DIA_3800.png"
    loop={ true }
  />
  <Video
    id="img__construccion_img"
    className="bloque__listado_hover_img"
    src="DIA_39"
    poster="/images/DIA_3900.png"
    loop={ true }
  />
  <Video
    id="img__energia_img"
    className="bloque__listado_hover_img"
    src="DIA_69"
    poster="/images/energia.png"
    loop={ true }
    onCanPlay={ () => setCanPlayVideos(true) }
  />
</div>
		</>
	);
}
