import { OrbitControls, Svg, useGLTF } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { Leva, button } from "leva";
import { useEffect, useRef, useState } from "react";
import Sizes from "../Utils/Sizes";
import ContactButton from "./3d_Components/ContactButton";
import DiamondFront from "./3d_Components/DiamondFront";
import MenuButton from "./3d_Components/MenuButton";
import Button3d from "./3d_Components/Button3d";

gsap.registerPlugin(
	ScrollTrigger,
);

export default function FrontCanvas() {

    const [load, setLoaded] = useState(false)
    const [contactCTA, setContactCTA] = useState(false)
    const [button3d, setButton3d] = useState(false)
    const [page, setPage] = useState('')
    const { width } = Sizes();

    const reload = () => {
        setLoaded(false)
        setTimeout(() => {
            setLoaded(true)
        }, (width <= 1024) ? 500 : 2000);
    }

    useEffect(() => {
        ScrollTrigger.addEventListener("refresh", reload);
        return () => {
            ScrollTrigger.removeEventListener("refresh", reload);
        }
    }, [])

    const { nodes } = useGLTF('./models/diamond.glb')

    useEffect(() => {
        if (document.querySelector('.texto__grande-marca')) {
            setPage('marca')
        } else if (document.querySelector('.bloque__vision__txtbig_container_producto')) {
            setPage('producto')
        } else if (document.querySelector('.bloque__vision__txtbig_container-inversion')) {
            setPage('inversion')
        }
        if (document.querySelector('.bloque__hablamos_cont_btn')) {
            setContactCTA(true)
        }
        if (document.querySelector('.btn__ver')) {
            setButton3d(true)
        }
    }, [load])

    useEffect(() => {
        // Chrome url bar fix on mobile
        document.querySelector('.front-canvas').style.height = window.outerHeight + 'px'
    }, []) 

    return <>
        <Canvas className='front-canvas' camera={ { fov: 10 } } frameloop="always" flat >

            { load && <>
                {/* MARCA */}
                { page === 'marca' && <>
                    <DiamondFront trigger={ '.texto__grande-marca' } scale={ [ .3, .15 ] } frontEnv="1" color="white" fixed={ true } nodes={ nodes } />
                    <DiamondFront trigger={ '.bloque__vision__txtbig-marca' } scale={ [ .2, .1 ] } frontEnv="2" color="green" positionX={ -.35 } nodes={ nodes } />
                </> }
                

                {/* PRODUCTO */}
                { page === 'producto' && <>
                    <DiamondFront trigger={ '.bloque__vision__txtbig_container_producto' } scale={ [ .3, .15 ] } frontEnv="3" color="green" producto={ true } nodes={ nodes } />
                </> }

                {/* INVERSION */}
                { page === 'inversion' && <>
                    <DiamondFront trigger={ '.bloque__vision__txtbig_container-inversion' } scale={ [ .3, .15 ] } frontEnv="3" color="green" fixed={ true } nodes={ nodes } />
                    <DiamondFront trigger={ '.bloque__vision__txtbig_container-inv2' } scale={ [ .27, .13 ] } frontEnv="3" color="green" positionX={ -.35 } fixed={ true } nodes={ nodes } />
                </> }

                { contactCTA && <>
                    <ContactButton />
                </> }

                {/* { button3d && <>
                    {[...document.querySelectorAll('.btn__ver')].map((value, index) =><>
                        <Button3d key={ value.id + index } element={ value } />
                    </>
                    )}
                </> } */}

            </> }

            {/* <MenuButton /> */}

            <Leva hidden />

        </Canvas>
    </>
}
