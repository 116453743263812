import React from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import gsap from 'gsap/all';
import { menuAnimation } from './Front/Front';
import { outCursor } from './Front/Components/Cursor';

const TransitionComponent = ({ children }) => {
  const location = useLocation();
  
  return (
    <SwitchTransition>
      <Transition
        key={location.pathname}
        timeout={1500}
        onEnter={(node) => {
          window.scrollTo(0, 0);
          gsap.set(node, { autoAlpha: 0 });
          gsap.timeline({ paused: true })
          .to(node, { autoAlpha: 1, duration: .3, delay: .05, onComplete: () => {
            document.body.style.backgroundColor = '#fff'
          } })
          .play();
          var ani_header = gsap.timeline({defaults: {duration: 1,ease: 'power2.out'}});
          ani_header.to('header',{top: '0'},0);
          ani_header.play();
        }}


        onExit={(node) => {
          document.body.style.backgroundColor = '#9BCAA6'
          gsap.timeline({ paused: true })
          .fromTo(node, { autoAlpha: 1 },{ autoAlpha: 0, duration: .5 })
          .play();
          menuAnimation(0)
          const html = document.querySelector('html');
          if (html) {
            html.classList.remove('loaded');
          }
          var ani_header = gsap.timeline({defaults: {duration: 1,ease: 'power2.out'}});
          ani_header.to('header',{top: '-150'},0);
          ani_header.play();
          outCursor()
        }}
      >
        {children}
      </Transition>
    </SwitchTransition>
  );
};

export default TransitionComponent;